var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="Project-transform u-fullParent js-transform"> <div class="Project-transform u-fullParent js-transform"> <div class="Project-overflow"> <div class="Scroll u-fullParent"> <div class="Bounding"> <div class="Section"> <div class="Project-header js-header"> <div class="Page-title"> <div class="Line Line--right Line--right--offset js-stagger"> <span class="vs-effect" data-type="translateX" data-speed="-0.1">'+
((__t=( cover[0] ))==null?'':__t)+
'</span> </div> <div class="Project-thumb" style="background-image: url(\''+
((__t=( assetsURL ))==null?'':__t)+
''+
((__t=( thumb ))==null?'':__t)+
'\')"></div> <div class="Line Line--left Line--left--offset js-stagger"> <span class="vs-effect" data-type="translateX" data-speed="0.1">'+
((__t=( cover[1] ))==null?'':__t)+
'</span> </div> </div> </div> </div> <div class="Section"> <div class="Project-content"> <div class="Device-content"> ';
 _.each(sections, function(section) { 
__p+=' <div class="Section-regular js-el"> <img src="'+
((__t=( assetsURL ))==null?'':__t)+
''+
((__t=( section.src ))==null?'':__t)+
'" alt=""> </div> ';
 }) 
__p+=' </div> </div> </div> <div class="Section"> <div class="Credits"> <div class="Credits-row"> <div class="Credits-col"> <span>Credits /</span> </div> <div class="Credits-col"> <div class="Line"> <a href="" class="Link" target="_blank">Art Direction by Marvin Schwaibold</a> </div> <div class="Line"> <a href="" class="Link" target="_blank">Development by Baptiste Briel</a> </div> </div> </div> </div> </div> </div> </div> </div> </div> </div>';
}
return __p;
};
