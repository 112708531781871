export default function(event) {
    let touches = event;

    if (event.originalEvent.changedTouches) {
        touches = event.originalEvent.changedTouches[0];
    } else if (event.changedTouches) {
        touches = event.changedTouches[0];
    }

    return touches;
}
