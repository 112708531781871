import _ from 'underscore'
import App from 'lib/app'
import size from 'size'
import Scroll from 'components/in-viewport'
import Slider from 'components/slider'
import template from './template.html'
import { props } from 'lib/decorators'

@props({
  template,
  className: 'Collection u-fullParent',
  ui: {
    scroll: '.Scroll',
    bounding: '.Bounding',
    section: '.Section',
    transform: '.js-transform',
    stagger: '.js-stagger',
    header: '.js-header',
    title: '.js-title',
    slider: '.js-slider',
    close: '.js-close',
    els: '.js-el'
  },
  events: {
    'click @ui.close': 'close'
  }
})

export default class Collection extends Scroll {

  initialize() {
    super.initialize()
    this.model = new App.Model(this.options.data)
  }

  onRender() {
    super.onRender()
    this.registerComponent(new Slider({
      data: App.data.site.photography,
      header: false,
      footer: true
    }), { parent: this.ui.slider[0] })
    this.once('resize', this.animateIn)
  }

  close(e) {
    App.router.navigate('/photography', { trigger: true })
  }

  inView(el, data, direction) {
    if (data.state) return
    data.state = true
    el.classList.add('in-viewport')
    const span = el.querySelector('span')
    const vertical = this.options.direction === 'y'
    const unit = (vertical ? size.height : size.width) / 2
    TweenMax.to(el, .2, {
      opacity: 1,
      ease: Linear.easeNone
    })
    TweenMax.fromTo(span, .75, {
      x: 5,
      autoAlpha: 0
    }, {
      x: 0,
      autoAlpha: 1,
      delay: .75
    })
    if (App.isDesktop && App.perf >= App.PERF_GOOD) {
      TweenMax.fromTo(el, 1.5, {
        scale: 1.1,
        x: vertical ? 0 : unit * (direction === 'top' ? -1 : 1),
        y: vertical ? unit * (direction === 'top' ? -1 : 1) : 0
      }, {
        scale: 1,
        x: 0,
        y: 0,
        ease: Expo.easeOut,
        clearProps: 'transform'
      })
    }
  }

  animateIn() {
    const tl = super.animateIn()
    const delay = App.firstRender ? 1.25 : 0
    tl.add(() => document.body.classList.add('has-Cursor-white'), 0)
    tl.staggerFromTo(this.ui.transform, 1.5, {
      cycle: {
        y: [size.height, -size.height]
      }
    }, {
      y: 0,
      ease: Quint.easeInOut
    }, 0, delay)
    tl.staggerFromTo(this.ui.stagger, .75, {
      x: 10,
      autoAlpha: 0
    }, {
      x: 0,
      autoAlpha: 1
    }, .1, delay + .85)
    tl.fromTo(this.ui.title, 1, {
      y: -140,
      autoAlpha: 0
    }, {
      y: 0,
      autoAlpha: 1
    }, delay + 1.25)
    return tl
  }
  
  animateOut() {
    const tl = super.animateOut()
    tl.add(() => document.body.classList.remove('has-Cursor-white'), 0)
    tl.staggerTo(this.ui.transform, 1.5, {
      cycle: {
        y: [size.height, -size.height]
      },
      ease: Quint.easeInOut
    }, 0, 0)
    return tl
  }

  onResize(width, height) {
    this.ui.header[0].style.height = `${height}px`
    super.onResize(width, height)
  }

  onBeforeDestroy() {
    super.onBeforeDestroy()
  }
}