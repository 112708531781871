import prefix from 'vendor-prefix'

const transform = prefix('transform')

const lerp = (currentValue, targetValue, inertia) => currentValue + (targetValue - currentValue) * inertia

const toFixed = function (n, p) {
  var p = p !== undefined ? Math.pow(10, p) : 1000
  return Math.round(n * p) / p
}

export default {
  transform: transform,
  lerp: lerp,
  toFixed: toFixed
}