import TouchEvents from 'lib/touch-events'
import size from 'size'

export default class InteractionManager {

  constructor(options = {}) {
    this.interactiveItems = []
    this.camera = options.camera
    this.raycaster = new THREE.Raycaster()
    this.mouse = new THREE.Vector2()
    this.touch = new TouchEvents({ el: window })
    this.touch.on('click', this.onClick, this)
    this.isEnabled = false
  }
  
  enable() {
    this.touch.enable()
    this.isEnabled = true
  }
  
  disable() {
    this.touch.disable()
    this.isEnabled = false
  }
  
  add(item) {
    this.interactiveItems.push(item)
  }
  
  remove(item) {
    this.interactiveItems.splice(this.interactiveItems.indexOf(item), 1)
  }
  
  raycast(event) {
    this.mouse.x = (event.clientX / size.width) * 2 - 1
    this.mouse.y = -(event.clientY / size.height) * 2 + 1
    this.camera.updateMatrixWorld()
    this.raycaster.setFromCamera(this.mouse, this.camera)
    this.raycaster.ray.origin.z = this.camera.far
    return this.raycaster.intersectObjects(this.interactiveItems, false)
  }
  
  onClick(event) {
    let intersects = this.raycast(event)
    if (!intersects.length) return
    const target = intersects[0].object
    target.onClick({ currentTarget: target })
  }

  destroy() {
    this.touch.off('click', this.onClick, this)
  }
}