import _ from 'underscore'
import App from 'lib/app'
import pad from 'left-pad'
import Mn from 'backbone.marionette'
import template from './template.html'
import { props } from 'lib/decorators'

function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

@props({
  template,
  className: 'Footer',
  ui: {
    hour: '.js-hour',
    minutes: '.js-minutes',
    mid: '.js-mid'
  },
  events: () => {
    const events = {}
    return events
  }
})

export default class FooterView extends Mn.View {

  initialize() {
    this.model = new App.Model()
  }
  
  onRender() {
    App.on('App.time:update', this.updateCounter, this)
    this.options.absolute && this.el.classList.add('is-absolute')
    this.once('resize', this.animateIn)
  }
  
  updateCounter() {
    const offset = +1
    const d = new Date()
    const utc = d.getTime() + (d.getTimezoneOffset() * 60000)
    const today = new Date(utc + (3600000 * offset))
    const hours = `${pad(today.getUTCHours(), 2, '0')}`
    const minutes = `${pad(today.getUTCMinutes(), 2, '0')}`
    let hour = (hours + 24 - 2) % 24
    let mid = 'AM'
    if (hour == 0) {
      hour = 12
    } else if (hour > 12) {
      hour = hour % 12
      mid = 'PM'
    }
    this.ui.hour[0].innerHTML = hour
    this.ui.minutes[0].innerHTML = minutes
    this.ui.mid[0].innerHTML = mid
  }
  
  animateIn() {
    const tl = super.animateIn()
    return tl
  }

  animateOut() {
    const tl = super.animateOut()
    return tl
  }

  onBeforeDestroy() {
    App.off('App.time:update', this.updateCounter, this)
  }
}