import { assign } from 'underscore'
import App from 'lib/app'
import Mn from 'backbone.marionette'
import template from './template.html'
import { props } from 'lib/decorators'

@props({
  template,
  className: 'Header',
  ui: {
    pager: '.js-pager',
    ariane: '.js-ariane'
  },
  events: () => {
    const events = { 'click @ui.pager': 'pager' }
    if (App.isDesktop) {
      assign(events, {
        'mouseenter': 'mouseenter',
        'mouseleave': 'mouseleave'
      })
    }
    return events
  }
})

export default class HeaderView extends Mn.View {

  initialize() {
    this.model = App.models.header
    this.options.autoDestroy = false
    this.visible = true
  }
  
  onRender() {
    App.on('header:color::transparent', (background) => {
      this.el.style.color = 'white'
      this.el.style.background = background
    })
    App.on('header:color::regular', () => {
      this.el.style.color = ''
      this.el.style.background = ''
    })
    App.on('header:ariane::update', (text) => {
      this.ui.ariane[0].innerHTML = text
    })
    this.once('resize', this.animateIn)
  }

  mouseenter(e) {
    this.focused = true
  }

  mouseleave(e) {
    this.focused = false
  }
  
  pager(e) {
    const { currentViewId } = App.router
    e.preventDefault()
    currentViewId !== 'home' && App.router.navigate('/home', { trigger: true })
    App.trigger('header:pager', e.currentTarget.getAttribute('data-pager'))
  }
  
  animateIn() {
    const tl = super.animateIn()
    return tl
  }

  animateOut() {
    const tl = super.animateOut()
    return tl
  }
}