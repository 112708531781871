var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="Slider-item-transform u-fullParent js-inview"> <div data-href="/photography/collection/'+
((__t=( collection ))==null?'':__t)+
'" class="Slider-item-image" style="background-image: url(\''+
((__t=( assetsURL ))==null?'':__t)+
''+
((__t=( cover ))==null?'':__t)+
'\')"></div> <div class="Slider-item-title"> <span>'+
((__t=( title ))==null?'':__t)+
'</span> </div> </div>';
}
return __p;
};
