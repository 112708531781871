import App from 'lib/app';
import Backbone from 'backbone';
import sniffer from 'sniffer';

export default class BaseModel extends Backbone.Model {
    initialize() {
        this.set(process.env.paths);
        this.set(sniffer.getInfos());
    }
}
