import _ from 'underscore';

/*
    Allow to use es6 extends with Backbone
    by moving properties (tagName, className...) to @props
    http://benmccormick.org/2015/07/06/backbone-and-es6-classes-revisited/
 */
export function props(value) {
    return function decorator(target) {
        _.extend(target.prototype, value);
    };
}
