import { assign, defer } from 'underscore'
import App from 'lib/app'
import Mn from 'backbone.marionette'
import View from 'components/in-viewport'
import Footer from 'components/footer'
import template from './template.html'
import { props } from 'lib/decorators'

@props({
  template,
  className: 'Home u-fullParent',
  ui: {
    scroll: '.Scroll',
    bounding: '.Bounding',
    section: '.Section',
    project: '.js-project',
    footer: '.js-footer',
    els: '.js-el',
    stagger: '.js-stagger',
    scaleX: '.js-scaleX',
    featured: '.js-featured',
    line: '.js-line'
  },
  events: () => {
    const events = {}
    if (App.isDesktop) {
      assign(events, {
        'mouseenter @ui.project': 'mouseenter',
        'mouseleave @ui.project': 'mouseleave'
      })
    }
    return events
  }
})

export default class Home extends View {

  initialize() {
    super.initialize()
    this.firstResize = true
    this.model = App.models.home.set({ entries: App.data.site.work.entries })
  }
  
  onRender() {
    super.onRender()
    App.on('header:pager', this.onPager, this)
    this.registerComponent(new Footer(), { parent: this.ui.footer[0] })
  }
  
  onPager(pager) {
    const scrollTo = () => {
      if (pager === 'intro') {
        TweenMax.set(this.ui.scroll, {
          scrollTo: 0,
          ease: Quint.easeInOut
        })
      } else {
        const el = this.el.querySelector(`[data-pager="${pager}"]`)
        const top = el.getBoundingClientRect().top
        const current = this.data.target
        const scrollTo = current + top
        TweenMax.set(this.ui.scroll, {
          scrollTo: scrollTo,
          ease: Quint.easeInOut
        })
      }
    }
    if (this.firstResize) {
      this.once('resize', scrollTo)
    } else {
      scrollTo()
    }
  }
  
  mouseenter(e) {
    const { currentTarget } = e
    const { parentNode } = currentTarget
    const thumbs = parentNode.querySelectorAll('.js-thumb')
    const tl = new TimelineMax({ paused: true })
    const rotation = (Math.random() * 20) * (Math.random() < 0.5 ? -1 : 1)
    parentNode.classList.add('is-hover')
    TweenMax.killTweensOf(thumbs)
    tl.staggerFromTo(thumbs, .1, {
      autoAlpha: 0,
      rotation: 0
    }, {
      autoAlpha: 1,
      cycle: {
        rotation: [-rotation, rotation],
      },
      ease: Quint.easeInOut
    }, .1, 0)
    tl.restart()
  }

  mouseleave(e) {
    const { currentTarget } = e
    const { parentNode } = currentTarget
    const thumbs = parentNode.querySelectorAll('.js-thumb')
    parentNode.classList.remove('is-hover')
    TweenMax.killTweensOf(thumbs)
    TweenMax.set(thumbs, {
      autoAlpha: 0
    })
  }
  
  animateIn() {
    const tl = super.animateIn()
    const delay = .4
    tl.staggerFromTo(this.ui.stagger, 1, {
      autoAlpha: 0,
      cycle: {
        x: [-100, 100]
      }
    }, {
      autoAlpha: 1,
      x: 0
    }, .1, delay)
    tl.fromTo(this.ui.scaleX, 1, {
      scaleX: 0
    }, {
      scaleX: 1
    }, .3 + delay)
    tl.fromTo(this.ui.featured, 1, {
      y: '50%',
      autoAlpha: 0
    }, {
      y: '0%',
      autoAlpha: 1
    }, .5 + delay)
    tl.staggerFromTo(this.ui.line, 1, {
      autoAlpha: 0,
      cycle: {
        x: ['100%', '-100%']
      }
    }, {
      autoAlpha: 1,
      x: '0%'
    }, .1, .5 + delay)
    return tl
  }
  
  animateOut(options) {
    const tl = super.animateOut()
    const { viewId } = options
    // if (viewId === 'work') {
    //   tl.to(this.el, 1, {
    //     autoAlpha: 1
    //   }, 0)
    // } else {
    //   tl.to(this.el, .3, {
    //     autoAlpha: 0,
    //     ease: Linear.easeNone
    //   }, 0)
    // }
    return tl
  }

  onResize(width, height) {
    super.onResize(width, height)
    this.firstResize = false
  }
  
  onBeforeDestroy() {
    super.onBeforeDestroy()
    App.off('header:pager', this.onPager, this)
  }
}