import { assign } from 'underscore'
import App from 'lib/app'
import size from 'size'
import View from 'components/in-viewport'
import template from './template.html'
import { props } from 'lib/decorators'

@props({
  template,
  className: 'Project u-fullParent',
  ui: {
    scroll: '.Scroll',
    bounding: '.Bounding',
    section: '.Section',
    transform: '.js-transform',
    header: '.js-header',
    stagger: '.js-stagger',
    els: '.js-el'
  },
  events: {}
})

export default class Project extends View {

  initialize() {
    super.initialize()
    this.options.unit = .5
    this.model = new App.Model(this.options.data)
    this.hasBlendMode = false
  }

  onRender() {
    super.onRender()
    const color = this.options.data.color
    App.trigger('header:ariane::update', this.model.get('title'))
    App.trigger('header:color::transparent', 'transparent')
    this.ui.transform[1].style.background = color
    this.once('resize', this.animateIn)
  }
  
  rAF() {
    super.rAF()
    if (this.hasBlendMode && App.header && !this.isBusy) {
      if (this.data.current >= size.height * .75) {
        App.header.el.style['mix-blend-mode'] = 'difference'
      } else {
        App.header.el.style['mix-blend-mode'] = ''
      }
    }
  }
  
  animateIn() {
    const tl = super.animateIn()
    tl.add(() => {
      App.header.el.classList.remove('is-hidden')
      App.header.visible = true
      this.data.direction = 'down'
    }, 0)
    tl.staggerFromTo(this.ui.stagger, 1, {
      autoAlpha: 0,
      cycle: {
        x: [size.width * .15, -size.width * .15]
      }
    }, {
      autoAlpha: 1,
      x: 0
    }, .15, 0)
    tl.add(() => this.hasBlendMode = true, 1)
    return tl
  }

  animateOut() {
    const tl = super.animateOut()
    tl.add(() => {
      App.trigger('header:ariane::update', 'creative developer')
    }, 0)
    return tl
  }

  onResize(width, height) {
    super.onResize(width, height)
    assign(this.ui.header[0].style, {
      height: `${height}px`,
      width: `${width}px`
    })
  }

  onBeforeDestroy() {
    super.onBeforeDestroy()
  }
}