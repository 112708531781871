import App from 'lib/app';
import Mn from 'backbone.marionette';
import _ from 'underscore';
import svgs from 'lib/svg';

const helpers = {
    // SVG sprite: pass id and optional className
    svg: function(svgId, className) {
        if (className) return svgs[svgId].replace('<svg', '<svg class="' + className + '"');
        return svgs[svgId];
    },

    assetPath: function(path) {
        return App.assetsURL + path;
    },

    i18nPath: function(path) {
        return App.assetsURL + 'i18n/' + App.lang + '/' + path;
    },

    linify(text, className) {
        className = className || 'line';
        return text.split('<br/>').map(function(line) {
            return `<div class="${className}"><p>${line}</p></div>`;
        }).join('');
    },

    spanify(text, className) {
        className = className || 'char';
        return text.split(' ').map(function(char) {
            if (char === '_') char = `&nbsp;`
            return `<span class="Span--outer"><span class="Span--inner">${char}</span></span>`;
        }).join('');
    }
}

/*
    Override the default Marionette.Renderer.render method
    to add support for global helpers
 */

Mn.Renderer.render = function(template, data) {
    if (!template) {
    throw new Mn.Error({
      name: 'TemplateNotFoundError',
      message: 'Cannot render the template since its false, null or undefined.'
    });
  }

  var templateFunc = _.isFunction(template) ? template : Mn.TemplateCache.get(template);
  return templateFunc(_.assign({}, data, helpers));
}
