import App from 'lib/app'
import size from 'size'
import Stats from 'stats.js'
import _ from 'underscore'
import $ from 'jquery'
import FPSControl from 'fps-control'
import touches from 'lib/touches'
import InteractionManager from 'lib/interaction-manager'

const OrbitControls = require('three-orbit-controls')(THREE)
const DEBUG = true

export default class Stage extends THREE.Scene {
  constructor(options = {}) {
    super()
    
    _.bindAll(this, 'onResize', 'onUpdate')

    this.el = options.canvas || document.createElement('canvas')
    this.$el = $(this.el)
    _.assign(this.el.style, {
      position: `absolute`,
      top: 0,
      left: 0,
      width: `100%`,
      height: `100%`,
      pointerEvents: `none`
    })
    
    this.renderer = new THREE.WebGLRenderer({ canvas: this.el, antialias: App.gpuPerf >= App.GPU_GOOD, alpha: false })
    this.renderer.setClearColor(0xdedede, 1)
    this.renderer.setPixelRatio(window.devicePixelRatio || 1)
    
    this.camera = new THREE.OrthographicCamera(-0.5, 0.5, 0.5, -0.5, -1200, 1200)
    this.camera.position.z = 1
    
    this.interactionManager = new InteractionManager({
      el: this.el,
      camera: this.camera
    })

    this.fps = new FPSControl(24)
    this.noiseFps = new FPSControl(14)

    App.uniforms = {
      time: {
        value: 0
      },
      fpsTime: {
        value: 0
      },
      noiseTime: {
        value: 0
      },
      resolution: {
        value: new THREE.Vector2(size.width, size.height)
      }
    }

    // DEBUG
    if (DEBUG) {
      window.scene = window.stage = this
      // let controls = new OrbitControls(this.camera, this.renderer.domElement)
      this.stats = new Stats()
      this.$el.after(this.stats.domElement)
      window.addControls = () => {
        let controls = new OrbitControls(this.camera, this.renderer.domElement)
      }
    }

    this.onResize(size.width, size.height)
    size.addListener(this.onResize)
    App.raf.subscribe('stage', this.onUpdate, this)
  }

  onResize(width, height) {
    this.renderer.setSize(width, height)
    let aspect = width / height
    this.camera.top = height * 0.5
    this.camera.bottom = -height * 0.5
    this.camera.left = -width * 0.5
    this.camera.right = width * 0.5
    this.camera.updateProjectionMatrix()
    App.uniforms.resolution.value.set(width, height)
  }
  
  set interactive(value) {
    if (value === true) {
      this.interactionManager.enable()
    } else {
      this.interactionManager.disable()
    }
  }
  
  get interactive() {
    return this.interactionManager.isEnabled
  }
  
  onUpdate(dt) {
    if (this.stats) {
      this.stats.begin()
    }
    App.uniforms.time.value += dt / 1000
    if (this.fps.check()) {
      App.uniforms.fpsTime.value += dt / 1000
    }
    if (this.noiseFps.check()) {
      App.uniforms.noiseTime.value += dt / 1000
    }
    this.renderer.render(this, this.camera)
    if (this.stats) {
      this.stats.end()
    }
  }

  destroy() {
    App.raf.unsubscribe('stage', this.onUpdate, this)
    size.removeListener(this.onResize)
  }
}