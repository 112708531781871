import $ from 'jquery'
import _ from 'underscore'
import Connection from 'network-information'
import App from 'lib/app'
import gpu from 'lib/gpu'
import Stage from 'lib/stage'
import Router from 'lib/router'
import BaseModel from 'lib/base-model'
import FontFaceObserver from 'fontfaceobserver'
import DrawSVGPlugin from 'DrawSVGPlugin'
import ScrollToPlugin from 'ScrollToPlugin'
import LoaderView from 'sections/loader'
import HeaderView from 'components/header'
import Noise from 'components/noise'
import size from 'size'

TweenLite.defaultEase = Quint.easeOut

if (!App.isIE && !App.isEdge) {
  window.devicePixelRatio = Math.min(window.devicePixelRatio || 1, 2);
}

App.connection = new Connection()
App.connection.init()

// App.gpu = gpu
// App.stage = new Stage()
// App.stage.interactive = true
// App.stage.interactionManager.enable()
// App.getRegion().$el.append(App.stage.el)

const fontA = new FontFaceObserver('Monarch', { weight: 400 })
Promise.all([fontA.load()]).then(() => {
  App.loadConfig({
    files: [{
      id: 'config',
      url: 'data/config.json',
      type: 'jsonp'
    }],
    callback: (result) => {
      const config = result.config
      App.data = config
      App.loader = new LoaderView({
        manifest: config.manifest,
        fonts: config.fonts,
        videos: config.videoCache
      })
      App.show(App.loader)
    }
  })
  App.once('assetsLoaded', (event) => {
    App.models = _.extend(App.models, _.mapObject(App.data.site, (data, key) => new BaseModel(data)))
    App.header = new HeaderView()
    App.show(App.header)
    App.router = new Router({
      defaultRoute: 'home',
      baseURL: App.baseURL
    })
    App.loader.animateOut()
  })
  App.start()
  App.show(new Noise())
})

const secondsToHMS = (d) => {
  d = Number(d)
  var h = Math.floor(d / 3600)
  var m = Math.floor(d % 3600 / 60)
  var s = Math.floor(d % 3600 % 60)
  return { h, m, s }
}

App.seconds = 0
App.time = { h: 0, m: 0, s: 0 }
setInterval(() => {
  App.seconds += 1
  App.time = secondsToHMS(App.seconds)
  App.trigger('App.time:update')
}, 1000)