import App from 'lib/app';
import Backbone from 'backbone';
import Marionette from 'backbone.marionette';
import _ from 'underscore';
import $ from 'jquery';
import {props} from 'lib/decorators';

@props({
    currentView: null,
    currentViewId: null,
    currentIndex: -1,
    nextView: null,
    nextViewId: null,
    nextIndex: 0,
    animateInDelay: 0,

    routes: {
        '*actions' : 'onSection'
    }
})

export default class AppRouter extends Backbone.Router {
    initialize(opts) {
        this.defaultRoute = opts.defaultRoute || '';
        this.views = _.reduce(require('../sections/**/*.js', {mode: 'hash'}), (reduce, val, key) => {
            reduce[key.replace('/index', '')] = val;
            return reduce;
        }, {});

        _.defer(() => {
            $(document).on('click', 'a[href]', (e) => this.routeLink(e));
            Backbone.history.start({
                pushState: !!window.history.pushState,
                root: opts.baseURL
            });
        });
    }

    routeLink(e) {
        const target = $(e.currentTarget);
        const href = target.attr('href');

        // Only route local links
        if (!href || href[0] !== '/') return;
        e.preventDefault();
        
        if (App.isBusy) return
        this.navigate(target.attr('href'), {trigger: true});
    }

    // Parse route from link/click
    onSection(path) {
        const fragments = (path || this.defaultRoute).split('/');
        const section = fragments[0];
        const slide = fragments[1];
        const currentId = this.nextViewId;

        if (Object.keys(this.views).indexOf(section) < 0) {
            return this.navigate(this.defaultRoute, {trigger: true});
        }

        this.nextViewId = section;
        this.nextSubviewId = slide;
        this.fragments = fragments;

        this.changePage();
    }

    changePage() {
        const NextView = this.views[this.nextViewId].default;
        const routeParams = {
            viewId: this.nextViewId,
            prevViewId: this.currentViewId,
            subviewId: this.nextSubviewId,
            prevSubviewId: this.currentSubviewId,
            fragments: this.fragments.slice()
        };

        if(!this.currentView) {
            routeParams.isFirst = true;
            this.currentView = new NextView(routeParams);
            this.currentView.once('animatedIn', function() {
                App.firstRender = false;
            });
            this.currentView.once('resize', this.currentView.animateIn, this.currentView);
            App.show(this.currentView);
            App.trigger('view:change', routeParams);
            App.trigger('route', routeParams);
        } else {
            // When changing content, keep the same page
            if (this.nextViewId == this.currentViewId) {
                App.trigger('view:updateContent', routeParams);
                App.trigger('route', routeParams);
                this.currentView.updateContent(routeParams);

                this.currentSubviewId = this.nextSubviewId;
                this.currentViewId = this.nextViewId;
                this.currentIndex  = this.nextIndex;

                return;
            }

            // Else, figure out transition timing
            let transitions = Marionette.View.TRANSITIONS;
            let oldView = this.currentView;
            this.currentView = new NextView(routeParams);
            let transitionTiming = (typeof this.currentView.transitionTiming == 'function' ?
                this.currentView.transitionTiming(this.currentViewId) : this.currentView.transitionTiming) || transitions.OUT_THEN_IN;

            this.currentView.once('resize', function(opts) {
                let outOptions = _.extend({}, opts, {
                    subviewId: this.subviewId,
                    viewId: this.nextViewId,
                    fragments: this.fragments.slice()
                });
                switch(transitionTiming) {
                    case transitions.IN_THEN_OUT:
                        this.currentView.once('animatedIn', function() {
                            oldView.animateOut(outOptions);
                        }, this);
                        this.currentView.animateIn();
                        break;

                    case transitions.OUT_THEN_IN:
                        oldView.once('animatedOut', function() {
                            this.currentView.animateIn();
                        }, this);
                        oldView.animateOut(outOptions);
                        break;

                    case transitions.OUT_AND_IN:
                        oldView.animateOut(outOptions);
                        this.currentView.animateIn();
                        break;

                    case transitions.CUSTOM:
                        oldView.once('animatedOut', function() {
                            this.currentView.onViewAnimatedOut();
                        }, this);
                        oldView.once('animatedCustom', function() {
                            this.currentView.animateIn();
                        }, this);
                        oldView.animateOut(outOptions);
                        break;
                }
            }, this);

            App.show(this.currentView);
            App.trigger('view:change', routeParams);
            App.trigger('route', routeParams);
        }

        this.currentSubviewId = this.nextSubviewId;
        this.currentViewId = this.nextViewId;
        this.currentIndex  = this.nextIndex;
    }
}
