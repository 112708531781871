import App from 'lib/app'
import _ from 'underscore'
import $ from 'jquery'
import TweenMax from 'TweenMax'
import Mn from 'backbone.marionette'
import component from 'lib/component-behavior'
import resize from 'lib/resize-behavior'
import size from 'size'

export default function() {
  Mn.View.TRANSITIONS = {
    IN_THEN_OUT: 1,
    OUT_THEN_IN: 2,
    OUT_AND_IN: 3,
    CUSTOM: 4
  }

  const BaseView = {
    options: {},
    isBusy: true,
    isDead: false,
    behaviors: [resize, component],

    transitionTiming: function(prev) {
      return  Mn.View.TRANSITIONS.OUT_AND_IN
    },
    
    animateIn: function(params) {
      App.isBusy = false
      this.trigger('animateIn', params)
      const tl = new TimelineMax({
        onComplete: this.onAnimatedIn,
        onCompleteScope: this
      })
      tl.set(this.el, {
        autoAlpha: 1
      }, 0)
      return tl
    },

    onAnimatedIn: function() {
      this.isBusy = false
      this.isDead = false
      this.trigger('animatedIn')
      App.trigger('view:animatedIn')
    },

    animateOut: function(params) {
      // if (this.isDead) return
      this.isBusy = true
      this.isDead = true
      this.trigger('animateOut', params)
      const tl = new TimelineMax({
        onComplete: this.onAnimatedOut,
        onCompleteScope: this
      })
      return tl
    },

    onAnimatedOut: function() {
      this.trigger('animatedOut')
      if (this.options.autoDestroy !== false) {
        this.destroy()
      }
    },

    onResize(width, height) {
      
    },

    updateContent: function() {
      console.warn('[BaseView updateContent] - Override me!')
    },

    nextTick: function(cb) {
      _.defer(_.bind(cb, this))
    }
  }

  _.extend(Mn.View.prototype, BaseView)
  _.extend(Mn.CollectionView.prototype, BaseView)
}