var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='';
 if (header) { 
__p+=' <div class="Slider-header"> <div class="Slider-row"> <span>Photography</span> <span>x</span> </div> <hr> </div> ';
 } 
__p+='';
}
return __p;
};
