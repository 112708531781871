import { assign } from 'underscore'
import Mn from 'backbone.marionette'
import App from 'lib/app'
import size from 'size'
import View from 'components/in-viewport'
import template from './template.html'
import { props } from 'lib/decorators'

@props({
  template,
  className: 'About u-fullParent',
  ui: {
    scroll: '.Scroll',
    bounding: '.Scroll-bounding',
    section: '.Scroll-section'
  },
  events: () => {
    const events = {}
    return events
  }
})

export default class About extends View {
  
  initialize() {
    super.initialize()
    this.model = App.models.about
  }
  
  onRender() {
    super.onRender()
  }

  onResize(width, height) {
    super.onResize(width, height)
  }
  
  animateIn() {
    const tl = super.animateIn()
    return tl
  }
  
  animateOut(options) {
    const tl = super.animateOut()
    return tl
  }
  
  onBeforeDestroy() {
    super.onBeforeDestroy()
  }
}