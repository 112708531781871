import { assign, find } from 'underscore'
import App from 'lib/app'
import size from 'size'
import View from 'components/in-viewport'
import Slider from 'components/slider'
import Collection from 'components/collection'
import Footer from 'components/footer'
import template from './template.html'
import { props } from 'lib/decorators'

@props({
  template,
  className: 'Photography u-fullParent',
  ui: {
    scroll: '.Scroll',
    bounding: '.Bounding',
    section: '.Section',
    footer: '.js-footer',
    slider: '.js-slider',
    stagger: '.js-stagger',
    scaleX: '.js-scaleX',
    featured: '.js-featured',
    line: '.js-line'
  },
  events: () => {
    const events = {}
    return events
  }
})

export default class Photography extends View {
  
  initialize() {
    super.initialize()
    this.model = App.models.photography
    this.collection = null
  }
  
  onRender() {
    super.onRender()
    this.registerComponent(new Slider({ data: App.data.site.photography }), { parent: this.ui.slider[0] })
    this.registerComponent(new Footer(), { parent: this.ui.footer[0] })
    this.updateContent(this.options)
  }
  
  updateContent(options) {
    const { subviewId, fragments } = options
    const collection = fragments[2]
    if (this.collection) {
      this.collection.animateOut()
      this.collection = null
    }
    if (subviewId && collection) {
      const data = find(App.data.site.photography.entries, (entry) => entry.collection === collection)
      this.collection = new Collection({ data })
      App.show(this.collection)
    }
  }
  
  animateIn() {
    const tl = super.animateIn()
    const delay = .4
    this.collection && tl.add(() => this.collection.data.direction = 'down', 1)
    tl.staggerFromTo(this.ui.stagger, 1, {
      autoAlpha: 0,
      cycle: {
        x: [-100, 100]
      }
    }, {
      autoAlpha: 1,
      x: 0
    }, .1, delay)
    tl.fromTo(this.ui.scaleX, 1, {
      scaleX: 0
    }, {
      scaleX: 1
    }, .3 + delay)
    tl.fromTo(this.ui.featured, 1, {
      y: '100%'
    }, {
      y: '0%'
    }, .5 + delay)
    tl.staggerFromTo(this.ui.line, 1, {
      autoAlpha: 0,
      cycle: {
        x: [size.width * .15, -size.width * .15]
      }
    }, {
      autoAlpha: 1,
      x: 0
    }, .1, .5 + delay)
    return tl
  }
  
  animateOut(options) {
    const tl = super.animateOut()
    // tl.to(this.el, .2, {
    //   autoAlpha: 0,
    //   ease: Linear.easeNone
    // }, 0)
    return tl
  }

  onResize(width, height) {
    super.onResize(width, height)
  }
  
  onBeforeDestroy() {
    super.onBeforeDestroy()
  }
}