import { bindAll, assign, defer, defaults } from 'underscore'
import size from 'size'
import App from 'lib/app'
import utils from 'lib/utils'
import Mn from 'backbone.marionette'
import prefix from 'vendor-prefix'
import VirtualScroll from 'virtual-scroll'
import template from './template.html'
import { props } from 'lib/decorators'

@props({
  template,
  className: 'inViewport'
})

export default class inViewport extends Mn.View {
  
  initialize() {
    bindAll(this, 'scroll', 'event', 'rAF')
    defaults(this.options, {
      virtual: !App.useNative,
      direction: 'y',
      threshold: App.isDesktop ? 200 : 100,
      mouseMultiplier: 1.15,
      touchMultiplier: 3,
      unit: .25
    })
    super.initialize()
    this.resizing = false
    this.vs = null
    this.sections = null
    this.cache = null
    this.header = false
    // this.scrollingTo = false
    this.data = {
      direction: null,
      ease: App.isDesktop ? .1 : .25,
      scrollable: true,
      locked: false,
      current: 0,
      target: 0,
      last: 0,
      fixed: 0,
      max: 0
    }
  }
  
  onRender() {
    if (this.options.virtual) {
      this.ui.scroll.addClass(`Scroll-virtual Scroll--${this.options.direction}`)
      this.vs = new VirtualScroll({ passive: true, mouseMultiplier: this.options.mouseMultiplier, touchMultiplier: this.options.touchMultiplier })
      this.vs.on(this.event, this)
    } else {
      this.ui.scroll.addClass(`Scroll-native Scroll--${this.options.direction}`)
      this.ui.scroll.on('scroll', this.scroll)
    }
    App.raf.subscribe(`Scroll:${this.cid}`, this.rAF, this)
  }
  
  event(e) {
    if (this.data.locked) return
    const delta = App.isDesktop ? e.deltaY : e.deltaX
    this.data.direction = (this.data.current >= this.data.last) ? 'down' : 'up'
    this.data.target += Math.round(delta * -0.5)
    this.clamp()
    this.data.last = this.data.current
  }
  
  scroll(e) {
    const el = this.ui.scroll[0]
    const scrollY = el.pageYOffset || el.scrollTop
    if (this.data.current !== this.data.last) {
      this.data.direction = (this.data.current >= this.data.last) ? 'down' : 'up'
    }
    this.data.target = scrollY
    this.clamp()
    this.data.last = this.data.current
  }
  
  clamp() {
    this.data.target = Math.round(Math.min(Math.max(this.data.target, 0), this.data.max))
  }
  
  rAF() {
    if (this.resizing) return
    this.data.current += (this.data.target - this.data.current) * this.data.ease
    this.rAFHeader()
    this.rAFSections()
    this.rAFElements()
  }
  
  rAFHeader() {
    if (App.header && !this.isBusy) {
      if (App.header.visible && !App.header.focused && this.data.direction === 'down' && this.data.current >= size.height * .5) {
        App.header.el.classList.add('is-hidden')
        App.header.visible = false
      }
      if (!App.header.visible && this.data.direction === 'up') {
        App.header.el.classList.remove('is-hidden')
        App.header.visible = true
      }
    }
  }
  
  rAFSections(options = { force: false }) {
    if (!this.sections) return
    const scrolling = this.data.scrolling = utils.toFixed(this.data.target, 0) !== utils.toFixed(this.data.current, 0)
    const fixed = this.data.fixed = scrolling ? 3 : 0
    const translate = utils.toFixed(this.data.current, this.data.fixed)
    const translate3d = this.options.direction === 'y' ? `translate3d(0,-${translate}px,0)` : `translate3d(-${translate}px,0,0)`
    this.sections.forEach((data, index) => {
      const { inView } = this.isInView(data)
      if (this.options.virtual && data.state || options.force === true) this.ui.section[index].style[utils.transform] = translate3d
      data.state = inView
      if (inView) {
        this.ui.section[index].classList.add('in-view')
      } else {
        this.ui.section[index].classList.remove('in-view')
        return
      }
      data.images.length && this.loadImages(data.images)
      data.fxs.length && this.applyFxs(data)
    })
  }
  
  rAFElements() {
    if (!this.cache) return
    const vertical = this.options.direction === 'y'
    const threshold = this.options.threshold || (App.isDesktop ? 150 : 20)
    const boundary = (vertical ? size.height : size.width)
    this.cache.forEach((data, index) => {
      const el = this.ui.els[index]
      const { inView, start, end, direction } = this.isInView(data)
      if (!data.state && (start > -threshold) && (end < (boundary + threshold))) return
      inView ? this.inView(el, data, direction) : this.outView(el, data, direction)
    })
  }

  applyFxs(data) {
    // if (!App.isDesktop || App.perf < App.PERF_HIGH) return
    // if (this.scrollingTo) return
    data.fxs.forEach((fx, i) => {
      const { el, type, speed } = fx
      const offset = this.getSpeed(data, speed)
      const translate3d = utils.toFixed(offset, this.data.fixed)
      let value = `none`
      if (type === 'translateX') value = `translate3d(${translate3d}px, 0, 0)`
      if (type === 'translateY') value = `translate3d(0, ${translate3d}px, 0)`
      el.style[utils.transform] = value
    })
  }

  getSpeed(data, speed) {
    const { middle, screen } = data
    const translate = utils.toFixed(this.data.current, this.data.fixed)
    const centered = translate - (middle - screen)
    return centered * speed
  }

  loadImages(images) {
    images.forEach((image) => {
      if (image.loading) return
      if (image.loaded) {
        const indexOf = images.indexOf(image)
        indexOf > -1 && images.splice(indexOf, 1)
      } else {
        image.loading = true
        this.loadImage(image)
      }
    })
  }
  
  loadImage(image) {
    const img = new Image()
    let src = image.src
    const done = () => {
      image.loading = false
      image.loaded = true
      image.el.style.backgroundImage = `url('${img.src}')`
      TweenMax.to(image.el, .4, {
        autoAlpha: 1,
        ease: Linear.easeNone
      })
    }
    const onload = () => {
      img.onload = null
      done()
    }
    const regular = () => {
      img.onload = onload
      img.src = src
    }
    if (img.decode) {
      img.src = src
      img.decode().then(done).catch((error) => regular())
    } else {
      regular()
    }
  }
  
  isInView(bounds) {
    const { top, right, bottom, left, width, height } = bounds
    const scrollY = App.useNative ? this.data.target : this.data.current
    const vertical = this.options.direction === 'y'
    const threshold = this.options.threshold
    const boundary = (vertical ? size.height : size.width)
    const start = (vertical ? top : left) - scrollY
    const end = (vertical ? bottom : right) - scrollY
    const inView = start < (boundary + threshold) && end > -threshold
    const direction = start < 0 ? 'top' : 'bottom'
    return {
      top, right, bottom, left,
      start, end,
      inView,
      direction
    }
  }
  
  inView(el, data, direction) {
    if (data.state) return
    data.state = true
    el.classList.add('in-viewport')
    TweenMax.to(el, .2, {
      opacity: 1,
      ease: Linear.easeNone
    })
    if (this.scrollingTo) return
    if (App.isDesktop && App.perf >= App.PERF_GOOD) {
      const vertical = this.options.direction === 'y'
      const unit = (vertical ? size.height : size.width) * this.options.unit
      TweenMax.fromTo(el, 1, {
        x: vertical ? 0 : unit * (direction === 'top' ? -1 : 1),
        y: vertical ? unit * (direction === 'top' ? -1 : 1) : 0
      }, {
        x: 0,
        y: 0,
        ease: Expo.easeOut,
        clearProps: 'transform'
      })
    }
  }
  
  outView(el, data, direction) {
    if (!data.state) return
    data.state = false
    el.classList.remove('in-viewport')
  }
  
  onResize(width, height) {
    this.resizing = true
    super.onResize(width, height)
    this.getBounding(width, height)
    this.getSections()
    this.getElements()
    defer(() => {
      this.options.virtual && this.rAFSections({ force: true })
      this.resizing = false
    })
  }
  
  getBounding(width, height) {
    const vertical = (this.options.direction === 'y')
    if (this.options.virtual) {
      const scrollY = Math.round(this.data.target)
      if (typeof this.ui.bounding[0] === 'string') return
      const bounding = this.ui.bounding[0].getBoundingClientRect()
      const value = vertical ? bounding.height : bounding.width
      this.data.max = value - (vertical ? height : width)
    } else {
      const el = this.ui.scroll[0]
      this.data.max = Math.max(el.scrollHeight, el.offsetHeight, el.clientHeight)
    }
  }
  
  getSections() {
    const vertical = this.options.direction === 'y'
    this.sections = []
    this.ui.section.each((index, el) => {
      el.style[utils.transform] = ''
      defer(() => {
        const bounds = el.getBoundingClientRect()
        const { inView, top, start, left, right, bottom } = this.isInView(bounds)
        const middle = vertical ? top + ((bottom - top) / 2) : left + ((right - left) / 2)
        const screen = (vertical ? size.height : size.width) / 2
        const fxs = this.getEffects(el)
        const images = this.getImages(el)
        this.sections.push({
          state: inView,
          top: top,
          left: left,
          right: right,
          bottom: bottom,
          middle: middle,
          screen: screen,
          fxs: fxs,
          images: images
        })
      })
    })
  }
  
  getImages(el) {
    const images = []
    const preloads = Array.from(el.querySelectorAll('[data-preload]'))
    preloads.forEach((el) => {
      const loading = false
      const loaded = !el.hasAttribute('data-preload')
      const src = el.getAttribute('data-src')
      images.push({
        el,
        src,
        loading,
        loaded
      })
    })
    return images
  }
  
  getEffects(el) {
    const fxs = []
    if (App.perf >= App.PERF_HIGH) {
      const effects = Array.from(el.querySelectorAll('.vs-effect'))
      effects.forEach((el, i) => {
        const type = el.getAttribute('data-type')
        const speed = el.getAttribute('data-speed') || 0
        fxs.push({ el, type, speed })
      })
    }
    return fxs
  }
  
  getElements() {
    if (this.ui.els) { // && !App.isIE && !App.isFirefox
      this.cache = []
      this.ui.els.each((index, el) => {
        const bounds = el.getBoundingClientRect()
        const { inView, top, left, right, bottom } = this.isInView(bounds)
        this.cache.push({
          state: inView,
          top: top,
          left: left,
          right: right,
          bottom: bottom
        })
      })
    }
  }
  
  onBeforeDestroy() {
    App.raf.unsubscribe(`Scroll:${this.cid}`)
    if (this.options.virtual) {
      this.vs.off(this.event, this)
      this.vs.destroy()
      this.vs = null
    } else {
      this.ui.scroll.off('scroll', this.scroll)
    }
    this.sections = null
    this.cache = null
    this.data = null
  }
}