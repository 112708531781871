import { defaults, bindAll, assign } from 'underscore'
import size from 'size'
import App from 'lib/app'
import utils from 'lib/utils'
import Mn from 'backbone.marionette'
import prefix from 'vendor-prefix'
import template from './template.html'
import { props } from 'lib/decorators'

@props({
  template,
  className: 'Slider-item',
  ui: {
    inview: '.js-inview'
  },
  events: () => {
    const events = {}
    return events
  }
})

export default class Item extends Mn.View {
  
  initialize(props) {
    super.initialize()
    this.model = new App.Model(this.options.data)
    this.index = this.model.get('index')
    this.currentX = 0
    this.targetX = 0
    this.oldScrollX = 0
    this.oldCurrentX = 0
    this.elWidth = 0
    this.slideWidth = 0
    this.sliderWidth = 0
    this.loaded = false
    this.loading = false
    this.inViewport = false
  }
  
  onRender() {
    this.el.classList.add(`is-${this.options.data.size}`)
    this.once('resize', this.animateIn)
  }
  
  onResize(width, height) {
    const length = this.options.slidesLength
    const index = this.options.index
    const margin = 25
    const bounding = 320
    
    this.elWidth = bounding
    this.slideWidth = this.elWidth + margin

    this.currentX = index * (this.slideWidth + margin)
    this.sliderWidth = (this.slideWidth + margin) * length
    this.targetX = this.currentX

    this.el.style.width = `${this.slideWidth}px`

    this.el.style[utils.transform] = `translate3d(${this.currentX}px, 0, 0)`
    this.oldScrollX = 0
  }

  getDirection() {
    if (this.currentX > this.oldCurrentX) {
      return -1
    } else if (this.currentX < this.oldCurrentX) {
      return 1
    } else {
      return 0
    }
  }
  
  update(direction, currentX) {
    this.targetX = this.targetX - (currentX - this.oldScrollX)
    this.currentX = parseFloat(utils.lerp(this.currentX, this.targetX, 0.25).toFixed(2)) // * this.options.direction

    const limitLeft = this.sliderWidth - this.slideWidth
    const limitRight = -this.slideWidth

    direction = direction || this.getDirection()

    const offsetLeft = limitLeft - this.currentX
    if (offsetLeft <= 0 && direction === -1) {
      this.targetX = this.targetX - limitLeft - this.slideWidth
      this.currentX = -this.slideWidth - offsetLeft
    }

    const offsetRight = limitRight - this.currentX
    if (offsetRight >= 0 && direction === 1) {
      this.targetX = (this.sliderWidth) + this.targetX
      this.currentX = this.sliderWidth - this.slideWidth - offsetRight
    }

    this.el.style[utils.transform] = `translate3d(${this.currentX}px, 0, 0)`

    this.oldScrollX = currentX
    this.oldCurrentX = this.currentX

    const inViewLeft = this.currentX >= -this.slideWidth
    const inViewRight = this.currentX <= size.width
    const inView = inViewLeft && inViewRight
    const inViewFrom = this.currentX < size.width / 2 ? 'left' : 'right'
    if (inView) {
      !this.inViewport && this.inView(inViewFrom)
    } else {
      this.inViewport && this.outView()
    }
  }
  
  inView(inViewFrom) {
    if (this.inViewport) return
    this.inViewport = true
    const unit = size.width * .25
    TweenMax.fromTo(this.ui.inview, 1.5, {
      x: inViewFrom === 'left' ? -unit : unit,
    }, {
      x: 0,
      ease: Expo.easeOut
    })
  }
  
  outView() {
    if (!this.inViewport) return
    this.inViewport = false
  }
}
