var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="Scroll u-fullParent"> <div class="Bounding"> <div class="Section"> <div class="Page-header Page-header--offset"> <div class="Line js-stagger"> <span>Photography</span> </div> <div class="Line js-stagger"> <hr class="js-scaleX"> <span>&amp; Filmmaking</span> </div> </div> <div class="Page-header-photo js-featured"> <span>New York, NY. 35mm.</span> <a href="/photography/collection/new-york" class="Page-header-photo-src Page-header-photo-src--portrait" style="background-image: url(\''+
((__t=( assetsURL ))==null?'':__t)+
'images/photography-featured.jpg\')"></a> </div> <div class="Page-title"> <div class="Line Line--right js-line"> <span class="vs-effect" data-type="translateX" data-speed="-0.1">2018</span> </div> <div class="Line js-line"> <span class="vs-effect" data-type="translateX" data-speed="0.1">-2019</span> </div> </div> <div class="Page-pager" data-pager="work"> <div class="Page-pager-row"> <span>selected work</span> </div> <hr> </div> </div> <div class="Section js-slider"></div> <div class="Section"> <div class="Page-header"> <div class="Line"> <span>Exploring</span> </div> <div class="Line"> <hr> <span>& shooting</span> </div> </div> <div class="Home-paragraph"> <div class="Device-content"> <p>I do web development for a living and there\'s a thing about Awards in this industry. If you\'re not part of it, you probably have no clue of what it is and you can just scroll past this list. I added it on my portfolio just to brag around with my fellows designers, developers and potential agencies.</p> </div> </div> <div class="Photography-footer js-footer"></div> </div> </div> </div>';
}
return __p;
};
