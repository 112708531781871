import App from 'lib/app'
import _ from 'underscore'
import $ from 'jquery'
import touches from 'lib/touches'
import Backbone from 'backbone'

export default class TouchEvents {

  constructor(options = {}) {
    this.el = options.el
    this.$el = $(this.el)
    _.extend(this, Backbone.Events)
    _.bindAll(this, 'onClick', 'onDown', 'onMove', 'onUp')
  }

  enable() {
    this.disable()
    this.$el.on('click', this.onClick)
    if (App.isDesktop) {
      this.$el.on('mousedown', this.onDown)
      this.$el.on('mousemove', this.onMove)
      this.$el.on('mouseup', this.onUp)
    } else {
      this.$el.on('touchstart', this.onDown)
      this.$el.on('touchmove', this.onMove)
      this.$el.on('touchend', this.onUp)
    }
  }

  disable() {
    this.$el.off('click', this.onClick)
    if (App.isDesktop) {
      this.$el.off('mousedown', this.onDown)
      this.$el.off('mousemove', this.onMove)
      this.$el.off('mouseup', this.onUp)
    } else {
      this.$el.off('touchstart', this.onDown)
      this.$el.off('touchmove', this.onMove)
      this.$el.off('touchend', this.onUp)
    }
  }

  onClick(event) {
    this.trigger('click', touches(event))
  }

  onDown(event) {
    this.trigger('touchstart', touches(event))
  }

  onMove(event) {
    event.preventDefault()
    event.stopPropagation()
    this.trigger('touchmove', touches(event))
  }

  onUp(event) {
    this.trigger('touchend', touches(event))
  }

  destroy() {
    this.disable()
  }
}
