import { assign, find } from 'underscore'
import Mn from 'backbone.marionette'
import App from 'lib/app'
import size from 'size'
import Project from 'components/project'
import template from './template.html'
import { props } from 'lib/decorators'

@props({
  template,
  className: 'Work u-fullParent',
  ui: {
    scroll: '.Scroll',
    bounding: '.Bounding',
    section: '.Section'
  },
  events: () => {
    const events = {}
    return events
  }
})

export default class Work extends Mn.View {
  
  initialize() {
    this.model = App.models.work
    this.project = null
  }
  
  onRender() {
    this.updateContent(this.options)
  }

  updateContent(options) {
    console.log('updateContent', options)
    const { subviewId } = options
    const data = find(this.model.get('entries'), (entry) => entry.slug === subviewId)
    this.project && this.project.animateOut()
    this.project = this.registerComponent(new Project({ data }))
  }
  
  onResize(width, height) {
    
  }
  
  animateIn() {
    const tl = super.animateIn()
    return tl
  }
  
  animateOut(options) {
    const tl = super.animateOut()
    tl.add(() => this.project.animateOut(), 0)
    return tl
  }
  
  onBeforeDestroy() {
    App.trigger('header:color::regular')
  }
}