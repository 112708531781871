import { assign, bindAll, defaults } from 'underscore'
import App from 'lib/app'
import Mn from 'backbone.marionette'
import Item from 'components/slider-item'
import Footer from 'components/footer'
import template from './template.html'
import { props } from 'lib/decorators'

@props({
  template,
  className: 'Slider',
  ui: {
    parent: '.js-parent'
  },
  events: () => {
    const events = {}
    if (App.isDesktop) {
      assign(events, {
        'mousemove': 'mousemove',
        'mousedown': 'mousedown',
        'mouseup': 'mouseup',
        'mouseenter': 'mouseenter',
        'mouseleave': 'mouseleave'
      })
    }
    return events
  }
})

export default class Slider extends Mn.View {
  
  initialize() {
    super.initialize()
    bindAll(this, 'rAF')
    defaults(this.options, { header: false, footer: false })
    this.model = new App.Model(this.options.data).set({ header: this.options.header })
    this.projects = null
    this.vs = null
    this.items = []
    this.data = {
      targetY: 0,
      currentY: 0
    }
    this.mouse = {
      down: false,
      startX: 0,
      currentX: 0,
      endX: 0
    }
  }
  
  onRender() {
    const entries = this.model.get('entries')
    entries.forEach((entry, index) => {
      const component = this.registerComponent(new Item({
        data: entry,
        index: index,
        slidesLength: entries.length
      }))
      this.items.push(component)
    })
    this.options.footer && this.registerComponent(new Footer({ absolute: true }))
    App.raf.subscribe(`Scroll:${this.cid}`, this.rAF, this)
    this.once('resize', this.animateIn)
  }
  
  mousedown(e) {
    if (this.mouse.down || e.which !== 1) return
    this.mouse.down = true
    this.mouse.startX = this.mouse.endX + e.clientX
    TweenMax.to(App.cursor, .4, { scale: .75 })
  }
  
  mousemove(e) {
    if (!this.mouse.down) return
    const currentX = this.mouse.startX - e.clientX
    this.data.currentY = this.mouse.currentX = currentX
  }
  
  mouseup(e) {
    this.mouse.down = false
    this.mouse.endX = this.mouse.currentX
    TweenMax.to(App.cursor, .4, { scale: 1 })
  }
  
  mouseenter(e) {
    App.trigger('cursor:none')
    TweenMax.to(App.cursor, .4, {
      autoAlpha: 1,
      ease: Linear.easeNone
    })
  }

  mouseleave(e) {
    this.mouseup(e)
    App.trigger('cursor:unnone')
    TweenMax.to(App.cursor, .4, {
      autoAlpha: 0,
      ease: Linear.easeNone
    })
  }
  
  rAF() {
    this.items.forEach((item) => item.update(null, this.data.currentY))
  }
  
  animateIn() {
    const tl = super.animateIn()
    return tl
  }
  
  animateOut(options) {
    const tl = super.animateOut()
    return tl
  }
  
  onResize(width, height) {
    const h = 480 + 150
    // TODO: fix height?
    this.el.style.height = `${height}px`
  }
  
  onBeforeDestroy() {
    App.raf.unsubscribe(`Scroll:${this.cid}`)
    this.projects = null
    this.items = null
    this.data = null
  }
}