import Marionette from 'backbone.marionette'
import _ from 'underscore'
import Raf from 'lib/raf'
import $ from 'jquery'
import size from 'size'
import prefix from 'vendor-prefix'
import sniffer from 'sniffer'
import visibility from 'visibility'
import Loader from 'assets-loader'
import helpers from 'lib/template-helpers'
import augmentView from 'lib/base-view'
import lottie from 'lottie-web'
import BaseModel from 'lib/base-model'
import BaseCollection from 'lib/base-collection'

augmentView()

const body = document.body
const App = new Marionette.Application({
  region: '.App'
})

export default App

App.scale = 1
App.models = Object.create(null)
App.textures = []
App.firstRender = true
App.env = process.env.ENV
// App.env === 'dev' && require('lib/observer')

App.Model = BaseModel
App.Collection = BaseCollection

// Add OS/Browser detection variables + classes on html
_.extend(App, sniffer.getInfos())
sniffer.addClasses(document.documentElement)

// Automatically assign environment variables from project.json (assetsURL, ...)
_.assign(App, process.env.paths)

// Allow to add multiples var to the App namespace
// in one instruction (instead of multiple lines App.foo = bar)
App.configure = (opts) => {
  _.assign(App, opts)
}

App.loadConfig = (options) => {
  const files = options.files
  const callback = options.onLoad
  const loader = new Loader({
    basePath: App.assetsURL,
    assets: options.files
  })
  loader.once('complete', (assets) => {
    const result = {}
    assets.forEach((asset) => result[asset.id] = asset.file)
    options.callback(result)
  })
  loader.start()
}

App.show = function(view) {
  App.getRegion().$el.append(view.render().el)
}

_.extend(App, {
  PERF_BAD: 0,
  PERF_LOW: 1,
  PERF_GOOD: 2,
  PERF_HIGH: 3
})

App.benchmark = () => {
  const dev = App.env == 'dev'
  dev && console.time('App.benchmark time')

  let array = []
  let quality = App.PERF_BAD
  const start = (window.performance || Date).now()
  for (let i = 0; i < 20000; i++) {
    array = Math.pow(Math.sin(Math.random()), 2)
  }

  const end = (window.performance || Date).now()
  const perf = end - start
  if (App.isTablet || App.isIE) {
    quality = App.PERF_BAD
  } else {
    if (perf < 7) quality = App.PERF_HIGH
    else if(perf < 14) quality = App.PERF_GOOD
    else if(perf < 22) quality = App.PERF_LOW
    else quality = App.PERF_BAD
  }

  App.perf = quality
  const print = quality == App.PERF_HIGH ? 'PERF_HIGH': quality == App.PERF_GOOD ? 'PERF_GOOD': quality == App.PERF_LOW  ? 'PERF_LOW': 'PERF_BAD'
  if (dev) {
    console.timeEnd('App.benchmark time')
    console.log(`App.perf is ${quality} (${print})`)
  }

  App.useNative = true // App.isDevice || App.perf < App.PERF_GOOD
  App.isDesktop && addCursors()
  document.documentElement.classList.add(print)
}

// Ignore HDPI screen over 2 - might be a nasty hack, dunno
if (App.isDroid) {
  window.devicePixelRatio = Math.min(window.devicePixelRatio || 1, 2)
}

// prefers-reduced-motion media feature
// https://webkit.org/blog/7551/responsive-design-for-motion/
const motion = matchMedia('(prefers-reduced-motion)')
const change = () => $(body)[motion.matches ? 'addClass' : 'removeClass']('has-reduced-motion')
motion.addListener(change)
change()

// Page Visibility API
const vsblty = visibility()
vsblty.on('show', () => App.trigger('window:visible'))
vsblty.on('hide', () => App.trigger('window:hidden'))

App.raf = new Raf()

const addCursors = function() {
  App.cursor = document.createElement('div')
  App.cursor.classList.add('Cursor')
  App.cursor.innerHTML = `
    <div class="Arrow Arrow--left"></div>
    <div class="Arrow Arrow--right"></div>
  `
  
  App.getRegion().$el.append(App.cursor)

  App.on('cursor:pointer', () => {
    body.classList.add('has-Pointer')
  })
  App.on('cursor:unpointer', () => {
    body.classList.remove('has-Pointer')
  })
  
  App.on('cursor:none', () => {
    body.classList.add('has-None')
  })
  App.on('cursor:unnone', () => {
    body.classList.remove('has-None')
  })
  
  App.mouse = {
    mouseX: size.width,
    mouseY: size.height,
    currentX: 0,
    currentY: 0,
    focused: { active: null, el: null }
  }
  
  window.addEventListener('mousemove', (e) => {
    const { clientX, clientY } = e
    App.mouse.mouseX = clientX
    App.mouse.mouseY = clientY
    // let intersects = App.stage.interactionManager.raycast(e)
    // if (!intersects.length) {
    //   if (App.mouse.focused.active) {
    //     App.mouse.focused.el.mouseleave()
    //     App.mouse.focused.el = null
    //     App.mouse.focused.active = false
    //   }
    //   return
    // }
    // const el = intersects[0].object
    // if (!App.mouse.focused.active) {
    //   App.mouse.focused.active = true
    //   App.mouse.focused.el = el
    //   el.mouseenter()
    // }
  })
  
  App.raf.subscribe('Mouse', () => {
    const { mouseX, mouseY, currentX, currentY } = App.mouse
    App.mouse.currentX += (mouseX - currentX) * .45
    App.mouse.currentY += (mouseY - currentY) * .45
    TweenMax.set(App.cursor, { x: currentX, y: currentY })
  })
}