import App from 'lib/app';
import _ from 'underscore';
import Mn from 'backbone.marionette';
import BaseModel from 'lib/base-model';
import template from './template.html';
import size from 'size';
import Canvas from 'canvas';
import { props } from 'lib/decorators';

@props({
  template,
  className: 'Noise u-fullParent'
})

export default class Noise extends Mn.View {
  
  initialize (opts) {
    _.bindAll(this, 'onUpdate');
    this.alpha = opts.alpha || (App.isDesktop ? .05 : .02);
    this.size = opts.size || (App.isDesktop ? 64 : 32);
    this.static = opts.static || false;
    this.grain = opts.grain || 1;
    this.bounds = opts.bounds || null;
    this.canvas = null;
    this.hiddenCanvas = null;
  }
  
  onRender() {
    this.hiddenCanvas = new Canvas({ ratio: 1 });
    this.hiddenCanvas.resize(this.size, this.size);
    this.canvas = new Canvas({ ratio: 1, parent: this.el });
    this.once('resize', this.start, this);
  }
  
  animateIn() {
    const tl = super.animateIn();
    return tl;
  }

  onResize (width, height) {
    if (this.options.fit) {
      this.bounds = this.el.getBoundingClientRect();
    }
    const w = this.bounds ? this.bounds.width : width;
    const h = this.bounds ? this.bounds.height : height;
    this.canvas.resize(w, h);
    if (this.static) this.onUpdate();
  }
  
  onUpdate() {
    if (this._isDestroyed) return;
    const hctx = this.hiddenCanvas.context;
    const imgData = hctx.getImageData(0, 0, this.size, this.size);
    for (let i = 0; i < imgData.data.length; i += 4) {
      const white = 255;
      const random = ~~(Math.random() * 255);
      imgData.data[i] = imgData.data[i + 1] = imgData.data[i + 2] = random // (this.options.white ? white : random);
      imgData.data[i + 3] = white // (this.options.white ? random : white);
    }
    hctx.putImageData(imgData, 0, 0);
    const ctx = this.canvas.context;
    const w = Math.ceil(size.width / this.size);
    const h = Math.ceil(size.height / this.size);
    const grain = this.grain;
    ctx.clearRect(0, 0, size.width, size.height);
    ctx.globalAlpha = this.alpha;
    for(let i = 0; i < w; i++) {
      for(let j = 0; j < h; j++) {
        ctx.drawImage(this.hiddenCanvas.el, i * this.size, j * this.size, this.size * grain, this.size * grain);
      }
    }
  }
  
  start() {
    if (this.static) this.onUpdate();
    else App.raf.subscribe(`Noise:${this.cid}`, this.onUpdate);
  }

  stop() {
    App.raf.unsubscribe(`Noise:${this.cid}`);
  }

  onBeforeDestroy() {
    this.stop();
    this.hiddenCanvas.destroy();
    this.canvas.destroy();
  }
}