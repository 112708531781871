import App from 'lib/app';
import _ from 'underscore';

const DEV = App.env == 'dev';

_.extend(App, {
    GPU_BAD: 0,
    GPU_LOW: 1,
    GPU_GOOD: 2,
    GPU_HIGH: 3
});

_.extend(App, {
    S3TC: 's3tc',
    ETC1: 'etc1',
    PVRTC: 'pvrtc',
    TRUECOLOR: 'truecolor'
});

// Assume medium perf by default
App.gpuPerf = App.GPU_LOW;

class GPU {
    constructor() {
        this.extensions = null;
        this.gpu = null;
        this.textures = null;
        this.hasGL = false;

        this.detect();
    }

    detect() {
        let gl = this.getGL();

        if (!gl) {
            if (DEV) {
                console.warn('[GPU] WebGL not supported!');
            }
            return;
        }

        this.detectPerformance(gl);
        this.detectTextureSupport(gl);

        if (DEV) {
            let perfId = 'GPU_BAD';
            if (App.gpuPerf == App.GPU_LOW) perfId = 'GPU_LOW';
            if (App.gpuPerf == App.GPU_GOOD) perfId = 'GPU_GOOD';
            if (App.gpuPerf == App.GPU_HIGH) perfId = 'GPU_HIGH';

            console.warn([
                `\n`,
                `[GPU] WebGL √`,
                `\n`,
                `[GPU] ${perfId} (${this.gpu})`,
                `\n`,
                `[GPU] textures: ${this.textures.toUpperCase()}`
            ].join(''));
        }
    }

    getGL() {
        let canvas = document.createElement('canvas');
        let gl;
        try {
            gl = canvas.getContext('experimental-webgl') || canvas.getContext('webgl');
            this.hasGL = true;
            return gl;
        } catch (t) {
            this.hasGL = false;
            return null;
        }
    }

    detectPerformance(gl) {
        let infos = gl.getExtension('WEBGL_debug_renderer_info');
        // let params = [
        //     gl.RENDERER,
        //     gl.VENDOR,
        //     gl.VERSION,
        //     gl.SHADING_LANGUAGE_VERSION,
        //     infos.UNMASKED_RENDERER_WEBGL,
        //     infos.UNMASKED_VENDOR_WEBGL
        // ];
        // params.forEach(function(p) {
        //     console.log(gl.getParameter(p).toLowerCase());
        // })
        this.gpu = gl.getParameter(infos.UNMASKED_RENDERER_WEBGL).toLowerCase();
        this.extensions = gl.getSupportedExtensions();
        let quality = App.GPU_LOW;

        // TODO: include blacklist?
        // way to get updated version?
        // https://wiki.mozilla.org/Blocklisting/Blocked_Graphics_Drivers
        // https://www.khronos.org/webgl/wiki/BlacklistsAndWhitelists
        // https://chromium.googlesource.com/chromium/src/gpu/+/master/config/software_rendering_list.json

        if (App.isDesktop) {
            // i.e. intel hd graphics 4000
            if (this.matchAll(['intel', 'hd'])) {
                quality = App.GPU_LOW;
            }

            // Assuming any dedicated GPU is good
            if (this.matchAll(['radeon', 'nvidia'])) {
                quality = App.GPU_GOOD;
            }

            // TODO:
            // gtx, gti, vega, quadro... ??
            // determine diff between GOOD and HIGH
        } else {
            if (App.isIos) {
                // From past experience, assuming something like
                // > a9 = great, <= a9 good, < a7 bad
                // https://developer.apple.com/library/content/documentation/DeviceInformation/Reference/iOSDeviceCompatibility/HardwareGPUInformation/HardwareGPUInformation.html

                // < A7
                let bad = _.times(5, (i) => {
                    return `a${i + 1}`;
                });
                // A7 -> A9
                let good = _.times(3, (i) => {
                    return `a${i + 7}`;
                });
                // A10+
                let high = _.times(10, (i) => {
                    return `a${i + 10}`;
                });
                if (this.matchAll(bad)) {
                    quality = App.GPU_BAD;
                }
                if (this.matchAll(good)) {
                    quality = App.GPU_GOOD;
                }
                if (this.matchAll(high)) {
                    quality = App.GPU_HIGH;
                }
            } else {
                // adreno (tm) 530 fast - samsung s7, one plus, zuk z2
                // mali g71 like 530
                // samsung s8, s7, one plus, pixel 2 => adreno 530
                // adreno (tm) 430 medium - nexus 6p
                // adreno (tm) 420 medium - nexus 6
                // adreno (tm) 418 medium - nexus 5X
                // mali-t760?? samsung s6, prob like 430
                // adreno (tm) 330 medium - nexus 5

                let isAdreno = this.match('adreno');
                let isMali = this.match('mali-t');

                if (isAdreno) {
                    quality = App.GPU_LOW;

                    if (this.matchVersion(400)) {
                        quality = App.GPU_GOOD;
                    }
                    if (this.matchVersion(500)) {
                        quality = App.GPU_HIGH;
                    }
                }

                if (isMali && this.matchVersion(70)) {
                    quality = App.GPU_HIGH;
                }

                // TODO:
                // nvidia tegra??? no version info
            }
        }

        App.gpuPerf = quality;
    }

    detectTextureSupport(gl) {
        let hasS3TC = gl.getExtension('WEBGL_compressed_texture_s3tc')
            || gl.getExtension('MOZ_WEBGL_compressed_texture_s3tc')
            || gl.getExtension('WEBKIT_WEBGL_compressed_texture_s3tc');

        let hasPVR = gl.getExtension('WEBGL_compressed_texture_pvrtc')
            || gl.getExtension('WEBKIT_WEBGL_compressed_texture_pvrtc');

        let hasETC1 = gl.getExtension('WEBGL_compressed_texture_etc1')
            || gl.getExtension('WEBKIT_WEBGL_compressed_texture_etc1');

        if (hasS3TC) {
            this.textures = App.S3TC;
        } else if (hasPVR) {
            this.textures = App.PVRTC;
        } else if (hasETC1) {
            this.textures = App.ETC1;
        } else {
            this.textures = App.TRUECOLOR;
        }

        // DEBUG
        this.textures = App.TRUECOLOR;
    }

    match(info) {
        return this.gpu.indexOf(info) > -1;
    }

    matchAll(infos) {
        return infos.reduce((reduce, info) => {
            return reduce || this.match(info);
        }, false);
    }

    // Get digits from a GPU string and return true
    // if gte to {version}
    matchVersion(version) {
        return parseInt(this.gpu.slice().replace(/[\D]/g, ''), 10) >= version;
    }
}

export default new GPU();
