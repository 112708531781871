import Mn from 'backbone.marionette';
import $ from 'jquery';

export default class ComponentBehavior extends Mn.Behavior {
    initialize() {
        this.view._components = [];
        this.view.registerComponent = this.registerComponent.bind(this.view);
    }

    registerComponent(component, options = {}) {
        if (this._components.indexOf(component) > -1) {
            console.warn('The component', component, 'has already been registered.');
            return;
        }
        this._components.push(component);

        let $el = $(options.parent || this.$el);
        if (options.position && options.position == 'after' || options.position == 'before') {
            $el[options.position](component.render().el);
        } else {
            $el.append(component.render().el);
        }

        return component;
    }

    onBeforeDestroy() {
        this.view._components.forEach(function(component) {
            if (component) component.destroy();
        })
        this.view._components.length = 0;
    }
}
