import $ from 'jquery'
import _ from 'underscore'
import TweenMax from 'TweenMax'
import Mn from 'backbone.marionette'
import App from 'lib/app'
import size from 'size'
import assign from 'object-assign'
import BaseModel from 'lib/base-model'
import FontFaceObserver from 'lib/fontfaceobserver'
import VideoCache from 'video-cache'
import Loader from 'assets-loader'
import template from './template.html'
import { props } from 'lib/decorators'

const devicePixelRatio = window.devicePixelRatio || 1

@props({
  template,
  className: 'Loader u-fullParent',
  ui: {
    percent: '.js-percent',
    progress: '.js-progress',
    stagger: '.js-stagger',
    hr: '.js-hr'
  }
})

export default class LoaderView extends Mn.View {
  
  initialize(options) {
    _.bindAll(this, 'checkProgress')
    this.model = new BaseModel(App.data.loader)
    this.videoCache = null
    this.loadEvents = 0
    this.currentProgress = 0
    this.loadedProgress = 0
    this.hasLoaded = false
    this.progress = {
      assets: 0,
      youtube: 0,
      fonts: 0,
      videoCache: 0
    }
  }
  
  onRender() {
    this.once('resize', this.animateIn, this)
    this.once('animatedIn', () => this.nextTick(this.benchmark))
  }
  
  animateIn() {
    const tl = super.animateIn()
    tl.staggerFromTo(this.ui.stagger, .75, {
      x: 15,
      autoAlpha: 0
    }, {
      x: 0,
      autoAlpha: 1
    }, .75)
    tl.fromTo(this.ui.hr, 1.5, {
      scaleX: 0
    }, {
      scaleX: 1,
      ease: Quint.easeInOut
    }, 0)
    tl.fromTo(this.ui.percent, 1, {
      y: '100%',
      autoAlpha: 0
    }, {
      y: '0%',
      autoAlpha: 1,
      ease: Expo.easeOut
    }, .75)
    return tl
  }
  
  animateOut(options) {
    const tl = super.animateOut()
    return tl
  }

  benchmark() {
    App.benchmark()
    this.nextTick(this.load)
  }
  
  onResize(width, height) {

  }

  load() {
    let manifest = this.options.manifest.common
    if (App.isPhone) manifest = manifest.concat(this.options.manifest.phone)
    if (App.isDesktop) manifest = manifest.concat(this.options.manifest.desktop)
    if (App.isTablet) manifest = manifest.concat(this.options.manifest.tablet)
    this.manifest = manifest

    this.loader = new Loader({
      basePath: App.assetsURL,
      crossOrigin: 'anonymous',
      assets: manifest
    })

    this.loader.on('progress', (progress) => {
      this.progress.assets = progress
      this.onProgress()
    })

    this.loader.on('error', _.bind(this.onError, this))
    this.loader.on('complete', this.onLoad.bind(this, 'assets'))
    this.loader.start()
    this.loadEvents++

    this.loadFonts()
    this.loadVideos()
    this.loadYouTube()

    App.raf.subscribe(`Loader:tick:${this.cid}`, this.checkProgress)
  }

  loadVideos () {
    if (!this.options.videos.length) return

    this.videoCache = new VideoCache({
      baseURL: App.assetsURL,
      eventName: 'canplaythrough',
      formats: ['mp4']
    })

    this.videoCache.on('progress', (progress) => {
      this.progress.videoCache = progress
      this.onProgress()
    })

    this.videoCache.on('error', this.onError.bind(this))
    this.videoCache.once('load', this.onLoad.bind(this, 'video'))
    this.videoCache.load(this.options.videos)
    this.loadEvents++
  }

  loadFonts () {
    if (!this.options.fonts) return
    const onFontLoaded = this.onLoad.bind(this, 'fonts')
    this.options.fonts.forEach(function(font) {
      let observer = new FontFaceObserver(font.name, font.options || {})
      observer.load().then(onFontLoaded, onFontLoaded)
      this.loadEvents++
    }, this)
  }

  loadYouTube () {
    if (this.options.youtube === true) {
      window.onYouTubeIframeAPIReady = this.onLoad.bind(this, 'youtube')
      $.ajax({
        url: 'https://www.youtube.com/player_api',
        dataType: 'script',
        timeout: this.options.youtubeTimeout || 5000
      }).fail(() => this.onLoad.bind(this, 'youtube'))
      this.loadEvents++
    }
  }

  onError(error) {
    console.warn('[Loader] error', error)
  }

  onProgress() {
    const progress = Object.keys(this.progress).reduce((total, key) => total + this.progress[key], 0)
    this.loadedProgress = progress / this.loadEvents
  }
  
  checkProgress() {
    if (this.currentProgress < this.loadedProgress) {
      this.currentProgress += 0.01
    }
    const progress = Math.round(this.currentProgress * 100)
    this.ui.progress[0].innerHTML = `${progress}%`
    if (this.hasLoaded && this.currentProgress >= 1 && this.loadedProgress >= 1) {
      this.hidePreloader()
    }
  }
  
  onLoad(from) {
    this.loaded++
    switch (from) {
      case 'assets':
      this.hasLoaded = true
      break
      case 'youtube':
      this.progress.youtube = 1
      break
      case 'fonts':
      this.progress.fonts++
      break
    }
    this.onProgress()
  }

  addData() {
    const data = {}
    const items = this.manifest.filter((item) => item.type == 'json' || item.type == 'jsonp' || item.indexOf('.json' > -1 || item.url && item.url.indexOf('.json') > -1))
    items.forEach((item) => {
      let id = item.id || item
      if (id.indexOf('/')) {
        id = id.substr(id.lastIndexOf('/') + 1)
      }
      id = id.replace('.json', '')
      data[id] = this.loader.get(item.id || item)
    })
    if (this.videoCache) {
      App.videoCache = this.videoCache
    }
    App.data = _.extend(App.data, data)
    App.posts = this.posts
    App.assets = this.loader
  }
  
  hidePreloader() {
    App.raf.unsubscribe(`Loader:tick:${this.cid}`)
    this.addData()
    setTimeout(() => App.trigger('assetsLoaded'), 500)
  }

  onBeforeDestroy() {
    this.loader.off()
    this.loader = null
    this.posts = null
  }
}
