import App from 'lib/app';
import BaseModel from 'lib/base-model';
import Backbone from 'backbone';
import { props } from 'lib/decorators';

@props({
    model: BaseModel
})

export default class BaseCollection extends Backbone.Collection {}
