import raf from 'raf';
import App from 'lib/app';

raf.polyfill();

export default class Raf {
  constructor () {
    this.subscribers = [];
    this.dt = 0;
    this.framerate = 16;
    this.elapsed = 0;
    this.now = Date.now();
    this.lt = this.now;
    this.update = this.update.bind(this);
    this.start();
  }
  
  update () {
    this.now = Date.now();
    this.dt = this.now - this.lt;
    this.elapsed += this.dt;
    if (this.elapsed >= this.framerate) {
      this.elapsed = 0;
      this.processUpdate();
    }
    this.lt = this.now;
    this.id = raf(this.update);
  }

  processUpdate () {
    for (let i = 0; i < this.subscribers.length; i++) {
      const subscriber = this.subscribers[i];
      subscriber.fn(this.dt);
    }
  }

  subscribe (id, fn) {
    this.subscribers.push({ id: id, fn });
  }
  
  unsubscribe (id) {
    for (let i = 0; i < this.subscribers.length; i++) {
      if (this.subscribers[i].id === id) {
        this.subscribers.splice(i, 1);
      }
    }
  }
  
  start () {
    App.env === 'dev' && console.log('App.raf started');
    this.id = raf(this.update);
  }

  stop () {
    App.env === 'dev' && console.log('App.raf stopped');
    raf.cancel(this.id);
  }

  resume () {
    App.env === 'dev' && console.log('App.raf resumed');
    this.start();
  }
}