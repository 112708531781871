import Mn from 'backbone.marionette';
import size from 'size';
import _ from 'underscore';

export default class ResizeBehavior extends Mn.Behavior {
    initialize() {
        _.bindAll(this, '_onResize');
        this.view.forceResize = this._onResize.bind(this);
    }

    onRender() {
        _.defer(this._onResize);
        size.addListener(this._onResize);
    }

    _onResize() {
        this.view.onResize(size.width, size.height);

        _.defer(() => {
            this.view.trigger('resize');
        });
    }

    onBeforeDestroy() {
        size.removeListener(this._onResize);
    }
}
