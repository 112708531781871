var _ = require('underscore');
module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='<div class="Collection-transform u-fullParent js-transform"> <div class="Collection-transform u-fullParent js-transform"> <div class="Scroll u-fullParent"> <div class="Bounding"> <div class="Section"> <div class="Collection-header js-header"> <div class="Collection-title js-title"> <span>'+
((__t=( title ))==null?'':__t)+
'</span> </div> <div class="Collection-meta"> <div class="Collection-meta-cover Collection-meta-cover--'+
((__t=( size ))==null?'':__t)+
'" style="background-image: url(\''+
((__t=( assetsURL ))==null?'':__t)+
''+
((__t=( cover ))==null?'':__t)+
'\')"></div> <div class="Collection-meta-data"> <div class="u-vCenter-parent"> <div class="u-vCenter"> <h1 class="js-stagger">'+
((__t=( title ))==null?'':__t)+
'</h1> ';
 _.each(lines, function(line) { 
__p+=' <span class="js-stagger">'+
((__t=( line ))==null?'':__t)+
'</span> ';
 }) 
__p+=' </div> </div> </div> </div> </div> </div> <div class="Section"> <div class="Collection-content"> ';
 _.each(entries, function(entry) { 
__p+=' <div class="Collection-col"> <div class="Collection-item Collection-item--'+
((__t=( entry.size ))==null?'':__t)+
' js-el"> <span>'+
((__t=( entry.label ))==null?'':__t)+
'</span> <div class="Collection-item--src" style="background-image: url(\''+
((__t=( assetsURL ))==null?'':__t)+
''+
((__t=( entry.src ))==null?'':__t)+
'\')"></div> </div> </div> ';
 }) 
__p+=' </div> </div> <div class="Section js-slider"></div> </div> </div> <div class="Collection-close js-close"> <span>X</span> </div> </div> </div>';
}
return __p;
};
